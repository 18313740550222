$iconWrapperWidth: 100px;

.iconWrapper {
    border: 1px solid #fff;
    padding: 15px;
    margin: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    flex: 0 0 $iconWrapperWidth;
    text-align: center;

    &:hover {
        border: 1px solid #e2e2e2;
    }
}

.iconName {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: $iconWrapperWidth;
    font-size: 0.7em;
    margin: 12px 0 4px;
    color: #7c7c7c;
}

.iconProvider {
    font-size: 0.65em;
    color: #afafaf;
}

.iconSize {
    font-size: 0.55em;
    color: #404040;
}

.icon {
    fill: #404040;
    cursor: pointer;

    &:hover {
        fill: #000;
    }

    svg {
        width: 36px;
        height: 36px;
    }
}
