$iconWrapperWidth: 100px;

.wrapper {

}

.clipboardMsg {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #9fdbb2;
    font-size: 0.7em;
    text-transform: uppercase;
    color: #004f00;
    padding: 15px 30px 12px;
    border-radius: 10px;
    opacity: 0.8;
    z-index: 2000;
}

.searchFormWrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: #fff;
    display: flex;
    justify-content: space-around;
    align-items: center;

    padding: 15px 0;
    border-bottom: 1px solid #ccc;
    z-index: 1000;
    box-shadow: 0 3px 10px 0 rgba(0,0,0,0.3);

    input {
        font-size: 1.2em;
        width: 50vw;
        padding: 5px 10px 5px 40px;
        color: #404040;
    }
}

.searchInputWrapper {
    position: relative;

    svg {
        position: absolute;
        left: 10px;
        top: 50%;
        transform: translate(0, -50%);
        width: 20px;
        height: 20px;
        fill: #a5a5a5;
    }
}

.showingInfo {
    text-align: center;
    font-size: 0.8em;
    color: #919191;
}

.iconsWrapper {
    display: flex;
    flex: 0 0 auto;
    justify-content: space-around;
    flex-wrap: wrap;
    margin-top: 100px;
}

.showMoreTile {
    display: flex;
    flex: 0 0 100px;
    min-height: 100px;
    flex-direction: column;
    justify-content: space-evenly;
    text-align: center;
    font-size: 0.7em;
    color: #7c7c7c;
    text-transform: uppercase;
    background-color: #ededed;
    border-color: #cbcbcb;
    cursor: pointer;

    &:hover {
        fill: #404040;
        color: #404040;
        background-color: #d2d2d2;
        border-color: #a5a5a5;
    }
}